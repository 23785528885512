@import url('https://fonts.googleapis.com/css2?family=Lato:wght@400;700&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Roboto+Condensed:wght@400;500;600;700&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Noto+Kufi+Arabic:wght@400;500;600;700&display=swap');

@tailwind base;
@tailwind components;
@tailwind utilities;

@layer components {
  .all-\[unset\] {
    all: unset;
  }
}

html, body {
  overflow-x: hidden;
}

:root {
  --black: rgba(22, 23, 34, 1);
  --bs-blue: rgba(13, 110, 253, 1);
  --bs-dark: rgba(33, 37, 41, 1);
  --bs-gray: rgba(108, 117, 125, 1);
  --bs-white: rgba(255, 255, 255, 1);
  --cap-01-font-family: "RobotoCondensed-Bold", Helvetica;
  --cap-01-font-size: 16px;
  --cap-01-font-style: normal;
  --cap-01-font-weight: 700;
  --cap-01-letter-spacing: 3.84px;
  --cap-01-line-height: normal;
  --cap-02-font-family: "RobotoCondensed-Regular", Helvetica;
  --cap-02-font-size: 12px;
  --cap-02-font-style: normal;
  --cap-02-font-weight: 400;
  --cap-02-letter-spacing: 0px;
  --cap-02-line-height: normal;
  --cap-03-font-family: "RobotoCondensed-Bold", Helvetica;
  --cap-03-font-size: 12px;
  --cap-03-font-style: normal;
  --cap-03-font-weight: 700;
  --cap-03-letter-spacing: 0.96px;
  --cap-03-line-height: normal;
  --font-sans-text-base-semibold-font-family: "Inter-SemiBold", Helvetica;
  --font-sans-text-base-semibold-font-size: 16px;
  --font-sans-text-base-semibold-font-style: normal;
  --font-sans-text-base-semibold-font-weight: 600;
  --font-sans-text-base-semibold-letter-spacing: 0px;
  --font-sans-text-base-semibold-line-height: 24px;
  --forms-input-label-default-font-family: "SFPro Text-Regular", Helvetica;
  --forms-input-label-default-font-size: 16px;
  --forms-input-label-default-font-style: normal;
  --forms-input-label-default-font-weight: 400;
  --forms-input-label-default-letter-spacing: -0.3199999928474426px;
  --forms-input-label-default-line-height: normal;
  --grey: rgba(245, 242, 240, 1);
  --heading-h1-font-family: "RobotoCondensed-Bold", Helvetica;
  --heading-h1-font-size: 64px;
  --heading-h1-font-style: normal;
  --heading-h1-font-weight: 700;
  --heading-h1-letter-spacing: -0.64px;
  --heading-h1-line-height: 82px;
  --heading-h2-font-family: "RobotoCondensed-Bold", Helvetica;
  --heading-h2-font-size: 48px;
  --heading-h2-font-style: normal;
  --heading-h2-font-weight: 700;
  --heading-h2-letter-spacing: -0.48px;
  --heading-h2-line-height: 64px;
  --heading-h3-font-family: "RobotoCondensed-Bold", Helvetica;
  --heading-h3-font-size: 40px;
  --heading-h3-font-style: normal;
  --heading-h3-font-weight: 700;
  --heading-h3-letter-spacing: -0.4px;
  --heading-h3-line-height: 56px;
  --heading-h4-font-family: "RobotoCondensed-Bold", Helvetica;
  --heading-h4-font-size: 32px;
  --heading-h4-font-style: normal;
  --heading-h4-font-weight: 700;
  --heading-h4-letter-spacing: -0.32px;
  --heading-h4-line-height: 44px;
  --heading-h5-font-family: "RobotoCondensed-Bold", Helvetica;
  --heading-h5-font-size: 24px;
  --heading-h5-font-style: normal;
  --heading-h5-font-weight: 700;
  --heading-h5-letter-spacing: -0.24px;
  --heading-h5-line-height: normal;
  --heading-h6-font-family: "RobotoCondensed-Bold", Helvetica;
  --heading-h6-font-size: 16px;
  --heading-h6-font-style: normal;
  --heading-h6-font-weight: 700;
  --heading-h6-letter-spacing: 0px;
  --heading-h6-line-height: 150%;
  --label-01-font-family: "RobotoCondensed-Regular", Helvetica;
  --label-01-font-size: 24px;
  --label-01-font-style: normal;
  --label-01-font-weight: 400;
  --label-01-letter-spacing: 0px;
  --label-01-line-height: 32px;
  --label-02-font-family: "RobotoCondensed-Regular", Helvetica;
  --label-02-font-size: 16px;
  --label-02-font-style: normal;
  --label-02-font-weight: 400;
  --label-02-letter-spacing: 0px;
  --label-02-line-height: normal;
  --label-03-font-family: "RobotoCondensed-Regular", Helvetica;
  --label-03-font-size: 18px;
  --label-03-font-style: normal;
  --label-03-font-weight: 400;
  --label-03-letter-spacing: 0px;
  --label-03-line-height: normal;
  --light-orange: rgba(255, 245, 235, 1);
  --paragraph-font-family: "RobotoCondensed-Regular", Helvetica;
  --paragraph-font-size: 16px;
  --paragraph-font-style: normal;
  --paragraph-font-weight: 400;
  --paragraph-letter-spacing: 0px;
  --paragraph-line-height: 24px;
  --primary: rgba(255, 210, 164, 1);
  --white: rgba(255, 255, 255, 1);
}
