@import "https://fonts.googleapis.com/css2?family=Lato:wght@400;700&display=swap";
@import "https://fonts.googleapis.com/css2?family=Roboto+Condensed:wght@400;500;600;700&display=swap";
@import "https://fonts.googleapis.com/css2?family=Noto+Kufi+Arabic:wght@400;500;600;700&display=swap";
*, :before, :after {
  box-sizing: border-box;
  border: 0 solid #e5e7eb;
}

:before, :after {
  --tw-content: "";
}

html, :host {
  -webkit-text-size-adjust: 100%;
  tab-size: 4;
  font-feature-settings: normal;
  font-variation-settings: normal;
  -webkit-tap-highlight-color: transparent;
  font-family: Lato, sans-serif;
  line-height: 1.5;
}

body {
  line-height: inherit;
  margin: 0;
}

hr {
  color: inherit;
  border-top-width: 1px;
  height: 0;
}

abbr:where([title]) {
  text-decoration: underline dotted;
}

h1, h2, h3, h4, h5, h6 {
  font-size: inherit;
  font-weight: inherit;
}

a {
  color: inherit;
  -webkit-text-decoration: inherit;
  text-decoration: inherit;
}

b, strong {
  font-weight: bolder;
}

code, kbd, samp, pre {
  font-feature-settings: normal;
  font-variation-settings: normal;
  font-family: ui-monospace, SFMono-Regular, Menlo, Monaco, Consolas, Liberation Mono, Courier New, monospace;
  font-size: 1em;
}

small {
  font-size: 80%;
}

sub, sup {
  vertical-align: baseline;
  font-size: 75%;
  line-height: 0;
  position: relative;
}

sub {
  bottom: -.25em;
}

sup {
  top: -.5em;
}

table {
  text-indent: 0;
  border-color: inherit;
  border-collapse: collapse;
}

button, input, optgroup, select, textarea {
  font-feature-settings: inherit;
  font-variation-settings: inherit;
  font-family: inherit;
  font-size: 100%;
  font-weight: inherit;
  line-height: inherit;
  letter-spacing: inherit;
  color: inherit;
  margin: 0;
  padding: 0;
}

button, select {
  text-transform: none;
}

button, input:where([type="button"]), input:where([type="reset"]), input:where([type="submit"]) {
  -webkit-appearance: button;
  background-color: #0000;
  background-image: none;
}

:-moz-focusring {
  outline: auto;
}

:-moz-ui-invalid {
  box-shadow: none;
}

progress {
  vertical-align: baseline;
}

::-webkit-inner-spin-button {
  height: auto;
}

::-webkit-outer-spin-button {
  height: auto;
}

[type="search"] {
  -webkit-appearance: textfield;
  outline-offset: -2px;
}

::-webkit-search-decoration {
  -webkit-appearance: none;
}

::-webkit-file-upload-button {
  -webkit-appearance: button;
  font: inherit;
}

summary {
  display: list-item;
}

blockquote, dl, dd, h1, h2, h3, h4, h5, h6, hr, figure, p, pre {
  margin: 0;
}

fieldset {
  margin: 0;
  padding: 0;
}

legend {
  padding: 0;
}

ol, ul, menu {
  margin: 0;
  padding: 0;
  list-style: none;
}

dialog {
  padding: 0;
}

textarea {
  resize: vertical;
}

input::placeholder, textarea::placeholder {
  opacity: 1;
  color: #9ca3af;
}

button, [role="button"] {
  cursor: pointer;
}

:disabled {
  cursor: default;
}

img, svg, video, canvas, audio, iframe, embed, object {
  vertical-align: middle;
  display: block;
}

img, video {
  max-width: 100%;
  height: auto;
}

[hidden] {
  display: none;
}

*, :before, :after, ::backdrop {
  --tw-border-spacing-x: 0;
  --tw-border-spacing-y: 0;
  --tw-translate-x: 0;
  --tw-translate-y: 0;
  --tw-rotate: 0;
  --tw-skew-x: 0;
  --tw-skew-y: 0;
  --tw-scale-x: 1;
  --tw-scale-y: 1;
  --tw-pan-x: ;
  --tw-pan-y: ;
  --tw-pinch-zoom: ;
  --tw-scroll-snap-strictness: proximity;
  --tw-gradient-from-position: ;
  --tw-gradient-via-position: ;
  --tw-gradient-to-position: ;
  --tw-ordinal: ;
  --tw-slashed-zero: ;
  --tw-numeric-figure: ;
  --tw-numeric-spacing: ;
  --tw-numeric-fraction: ;
  --tw-ring-inset: ;
  --tw-ring-offset-width: 0px;
  --tw-ring-offset-color: #fff;
  --tw-ring-color: #3b82f680;
  --tw-ring-offset-shadow: 0 0 #0000;
  --tw-ring-shadow: 0 0 #0000;
  --tw-shadow: 0 0 #0000;
  --tw-shadow-colored: 0 0 #0000;
  --tw-blur: ;
  --tw-brightness: ;
  --tw-contrast: ;
  --tw-grayscale: ;
  --tw-hue-rotate: ;
  --tw-invert: ;
  --tw-saturate: ;
  --tw-sepia: ;
  --tw-drop-shadow: ;
  --tw-backdrop-blur: ;
  --tw-backdrop-brightness: ;
  --tw-backdrop-contrast: ;
  --tw-backdrop-grayscale: ;
  --tw-backdrop-hue-rotate: ;
  --tw-backdrop-invert: ;
  --tw-backdrop-opacity: ;
  --tw-backdrop-saturate: ;
  --tw-backdrop-sepia: ;
  --tw-contain-size: ;
  --tw-contain-layout: ;
  --tw-contain-paint: ;
  --tw-contain-style: ;
}

.container {
  width: 100%;
}

@media (width >= 640px) {
  .container {
    max-width: 640px;
  }
}

@media (width >= 768px) {
  .container {
    max-width: 768px;
  }
}

@media (width >= 1024px) {
  .container {
    max-width: 1024px;
  }
}

@media (width >= 1280px) {
  .container {
    max-width: 1280px;
  }
}

@media (width >= 1536px) {
  .container {
    max-width: 1536px;
  }
}

.invisible {
  visibility: hidden;
}

.fixed {
  position: fixed;
}

.absolute {
  position: absolute;
}

.relative {
  position: relative;
}

.inset-0 {
  inset: 0;
}

.left-0 {
  left: 0;
}

.left-3 {
  left: .75rem;
}

.right-2 {
  right: .5rem;
}

.top-0 {
  top: 0;
}

.top-2 {
  top: .5rem;
}

.top-6 {
  top: 1.5rem;
}

.z-10 {
  z-index: 10;
}

.z-20 {
  z-index: 20;
}

.mx-auto {
  margin-left: auto;
  margin-right: auto;
}

.my-1 {
  margin-top: .25rem;
  margin-bottom: .25rem;
}

.my-2 {
  margin-top: .5rem;
  margin-bottom: .5rem;
}

.mb-1 {
  margin-bottom: .25rem;
}

.mb-2 {
  margin-bottom: .5rem;
}

.mb-4 {
  margin-bottom: 1rem;
}

.mb-6 {
  margin-bottom: 1.5rem;
}

.mb-auto {
  margin-bottom: auto;
}

.ml-2 {
  margin-left: .5rem;
}

.ml-auto {
  margin-left: auto;
}

.mt-8 {
  margin-top: 2rem;
}

.block {
  display: block;
}

.flex {
  display: flex;
}

.table {
  display: table;
}

.hidden {
  display: none;
}

.h-0 {
  height: 0;
}

.h-1 {
  height: .25rem;
}

.h-5 {
  height: 1.25rem;
}

.h-6 {
  height: 1.5rem;
}

.h-8 {
  height: 2rem;
}

.h-\[60vh\] {
  height: 60vh;
}

.h-auto {
  height: auto;
}

.h-full {
  height: 100%;
}

.max-h-\[60vh\] {
  max-height: 60vh;
}

.min-h-\[100vh\], .min-h-screen {
  min-height: 100vh;
}

.w-1\/2 {
  width: 50%;
}

.w-20 {
  width: 5rem;
}

.w-5 {
  width: 1.25rem;
}

.w-6 {
  width: 1.5rem;
}

.w-8 {
  width: 2rem;
}

.w-\[120px\] {
  width: 120px;
}

.w-\[80\%\] {
  width: 80%;
}

.w-\[95\%\] {
  width: 95%;
}

.w-full {
  width: 100%;
}

.min-w-\[100\%\], .min-w-full {
  min-width: 100%;
}

.max-w-md {
  max-width: 28rem;
}

.max-w-screen-xl {
  max-width: 1280px;
}

.max-w-xs {
  max-width: 20rem;
}

.flex-grow {
  flex-grow: 1;
}

.touch-none {
  touch-action: none;
}

.flex-row {
  flex-direction: row;
}

.flex-col {
  flex-direction: column;
}

.flex-wrap {
  flex-wrap: wrap;
}

.items-center {
  align-items: center;
}

.justify-end {
  justify-content: flex-end;
}

.justify-center {
  justify-content: center;
}

.justify-between {
  justify-content: space-between;
}

.gap-4 {
  gap: 1rem;
}

.gap-x-2 {
  column-gap: .5rem;
}

.gap-y-10 {
  row-gap: 2.5rem;
}

.gap-y-4 {
  row-gap: 1rem;
}

.space-x-2 > :not([hidden]) ~ :not([hidden]) {
  --tw-space-x-reverse: 0;
  margin-right: calc(.5rem * var(--tw-space-x-reverse));
  margin-left: calc(.5rem * calc(1 - var(--tw-space-x-reverse)));
}

.space-x-4 > :not([hidden]) ~ :not([hidden]) {
  --tw-space-x-reverse: 0;
  margin-right: calc(1rem * var(--tw-space-x-reverse));
  margin-left: calc(1rem * calc(1 - var(--tw-space-x-reverse)));
}

.space-x-8 > :not([hidden]) ~ :not([hidden]) {
  --tw-space-x-reverse: 0;
  margin-right: calc(2rem * var(--tw-space-x-reverse));
  margin-left: calc(2rem * calc(1 - var(--tw-space-x-reverse)));
}

.space-y-10 > :not([hidden]) ~ :not([hidden]) {
  --tw-space-y-reverse: 0;
  margin-top: calc(2.5rem * calc(1 - var(--tw-space-y-reverse)));
  margin-bottom: calc(2.5rem * var(--tw-space-y-reverse));
}

.space-y-2 > :not([hidden]) ~ :not([hidden]) {
  --tw-space-y-reverse: 0;
  margin-top: calc(.5rem * calc(1 - var(--tw-space-y-reverse)));
  margin-bottom: calc(.5rem * var(--tw-space-y-reverse));
}

.space-y-4 > :not([hidden]) ~ :not([hidden]) {
  --tw-space-y-reverse: 0;
  margin-top: calc(1rem * calc(1 - var(--tw-space-y-reverse)));
  margin-bottom: calc(1rem * var(--tw-space-y-reverse));
}

.self-end {
  align-self: flex-end;
}

.self-center {
  align-self: center;
}

.overflow-y-auto {
  overflow-y: auto;
}

.whitespace-pre-line {
  white-space: pre-line;
}

.break-all {
  word-break: break-all;
}

.rounded {
  border-radius: .25rem;
}

.rounded-2xl {
  border-radius: 1rem;
}

.rounded-lg {
  border-radius: .5rem;
}

.rounded-md {
  border-radius: .375rem;
}

.rounded-xl {
  border-radius: .75rem;
}

.border {
  border-width: 1px;
}

.border-2 {
  border-width: 2px;
}

.border-b {
  border-bottom-width: 1px;
}

.border-c-background {
  --tw-border-opacity: 1;
  border-color: rgb(245 239 234 / var(--tw-border-opacity));
}

.border-c-main {
  --tw-border-opacity: 1;
  border-color: rgb(196 143 90 / var(--tw-border-opacity));
}

.bg-c-background {
  --tw-bg-opacity: 1;
  background-color: rgb(245 239 234 / var(--tw-bg-opacity));
}

.bg-c-green {
  --tw-bg-opacity: 1;
  background-color: rgb(68 86 86 / var(--tw-bg-opacity));
}

.bg-c-main {
  --tw-bg-opacity: 1;
  background-color: rgb(196 143 90 / var(--tw-bg-opacity));
}

.bg-c-white {
  --tw-bg-opacity: 1;
  background-color: rgb(255 255 255 / var(--tw-bg-opacity));
}

.bg-gray-600 {
  --tw-bg-opacity: 1;
  background-color: rgb(75 85 99 / var(--tw-bg-opacity));
}

.bg-white {
  --tw-bg-opacity: 1;
  background-color: rgb(255 255 255 / var(--tw-bg-opacity));
}

.bg-opacity-50 {
  --tw-bg-opacity: .5;
}

.bg-cover {
  background-size: cover;
}

.bg-center {
  background-position: center;
}

.stroke-c-main {
  stroke: #c48f5a;
}

.object-contain {
  object-fit: contain;
}

.object-cover {
  object-fit: cover;
}

.p-14 {
  padding: 3.5rem;
}

.p-2 {
  padding: .5rem;
}

.p-4 {
  padding: 1rem;
}

.p-5 {
  padding: 1.25rem;
}

.p-6 {
  padding: 1.5rem;
}

.px-2 {
  padding-left: .5rem;
  padding-right: .5rem;
}

.px-4 {
  padding-left: 1rem;
  padding-right: 1rem;
}

.py-2 {
  padding-top: .5rem;
  padding-bottom: .5rem;
}

.pb-2 {
  padding-bottom: .5rem;
}

.pb-4 {
  padding-bottom: 1rem;
}

.pt-10 {
  padding-top: 2.5rem;
}

.pt-2 {
  padding-top: .5rem;
}

.pt-20 {
  padding-top: 5rem;
}

.pt-4 {
  padding-top: 1rem;
}

.text-left {
  text-align: left;
}

.text-center {
  text-align: center;
}

.text-right {
  text-align: right;
}

.text-start {
  text-align: start;
}

.font-arabic {
  font-family: Noto Kufi Arabic;
}

.font-roboto {
  font-family: Roboto Condensed;
}

.text-2xl {
  font-size: 1.5rem;
  line-height: 2rem;
}

.text-3xl {
  font-size: 1.875rem;
  line-height: 2.25rem;
}

.text-4xl {
  font-size: 2.25rem;
  line-height: 2.5rem;
}

.text-lg {
  font-size: 1.125rem;
  line-height: 1.75rem;
}

.text-sm {
  font-size: .875rem;
  line-height: 1.25rem;
}

.text-xl {
  font-size: 1.25rem;
  line-height: 1.75rem;
}

.font-bold {
  font-weight: 700;
}

.font-semibold {
  font-weight: 600;
}

.uppercase {
  text-transform: uppercase;
}

.italic {
  font-style: italic;
}

.text-c-green {
  --tw-text-opacity: 1;
  color: rgb(68 86 86 / var(--tw-text-opacity));
}

.text-c-main {
  --tw-text-opacity: 1;
  color: rgb(196 143 90 / var(--tw-text-opacity));
}

.text-gray-300 {
  --tw-text-opacity: 1;
  color: rgb(209 213 219 / var(--tw-text-opacity));
}

.text-gray-600 {
  --tw-text-opacity: 1;
  color: rgb(75 85 99 / var(--tw-text-opacity));
}

.text-gray-700 {
  --tw-text-opacity: 1;
  color: rgb(55 65 81 / var(--tw-text-opacity));
}

.text-white {
  --tw-text-opacity: 1;
  color: rgb(255 255 255 / var(--tw-text-opacity));
}

.opacity-10 {
  opacity: .1;
}

.opacity-100 {
  opacity: 1;
}

.shadow-lg {
  --tw-shadow: 0 10px 15px -3px #0000001a, 0 4px 6px -4px #0000001a;
  --tw-shadow-colored: 0 10px 15px -3px var(--tw-shadow-color), 0 4px 6px -4px var(--tw-shadow-color);
  box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000), var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow);
}

.shadow-md {
  --tw-shadow: 0 4px 6px -1px #0000001a, 0 2px 4px -2px #0000001a;
  --tw-shadow-colored: 0 4px 6px -1px var(--tw-shadow-color), 0 2px 4px -2px var(--tw-shadow-color);
  box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000), var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow);
}

.shadow-xl {
  --tw-shadow: 0 20px 25px -5px #0000001a, 0 8px 10px -6px #0000001a;
  --tw-shadow-colored: 0 20px 25px -5px var(--tw-shadow-color), 0 8px 10px -6px var(--tw-shadow-color);
  box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000), var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow);
}

.filter {
  filter: var(--tw-blur) var(--tw-brightness) var(--tw-contrast) var(--tw-grayscale) var(--tw-hue-rotate) var(--tw-invert) var(--tw-saturate) var(--tw-sepia) var(--tw-drop-shadow);
}

html, body {
  overflow-x: hidden;
}

:root {
  --black: #161722;
  --bs-blue: #0d6efd;
  --bs-dark: #212529;
  --bs-gray: #6c757d;
  --bs-white: #fff;
  --cap-01-font-family: "RobotoCondensed-Bold", Helvetica;
  --cap-01-font-size: 16px;
  --cap-01-font-style: normal;
  --cap-01-font-weight: 700;
  --cap-01-letter-spacing: 3.84px;
  --cap-01-line-height: normal;
  --cap-02-font-family: "RobotoCondensed-Regular", Helvetica;
  --cap-02-font-size: 12px;
  --cap-02-font-style: normal;
  --cap-02-font-weight: 400;
  --cap-02-letter-spacing: 0px;
  --cap-02-line-height: normal;
  --cap-03-font-family: "RobotoCondensed-Bold", Helvetica;
  --cap-03-font-size: 12px;
  --cap-03-font-style: normal;
  --cap-03-font-weight: 700;
  --cap-03-letter-spacing: .96px;
  --cap-03-line-height: normal;
  --font-sans-text-base-semibold-font-family: "Inter-SemiBold", Helvetica;
  --font-sans-text-base-semibold-font-size: 16px;
  --font-sans-text-base-semibold-font-style: normal;
  --font-sans-text-base-semibold-font-weight: 600;
  --font-sans-text-base-semibold-letter-spacing: 0px;
  --font-sans-text-base-semibold-line-height: 24px;
  --forms-input-label-default-font-family: "SFPro Text-Regular", Helvetica;
  --forms-input-label-default-font-size: 16px;
  --forms-input-label-default-font-style: normal;
  --forms-input-label-default-font-weight: 400;
  --forms-input-label-default-letter-spacing: -.32px;
  --forms-input-label-default-line-height: normal;
  --grey: #f5f2f0;
  --heading-h1-font-family: "RobotoCondensed-Bold", Helvetica;
  --heading-h1-font-size: 64px;
  --heading-h1-font-style: normal;
  --heading-h1-font-weight: 700;
  --heading-h1-letter-spacing: -.64px;
  --heading-h1-line-height: 82px;
  --heading-h2-font-family: "RobotoCondensed-Bold", Helvetica;
  --heading-h2-font-size: 48px;
  --heading-h2-font-style: normal;
  --heading-h2-font-weight: 700;
  --heading-h2-letter-spacing: -.48px;
  --heading-h2-line-height: 64px;
  --heading-h3-font-family: "RobotoCondensed-Bold", Helvetica;
  --heading-h3-font-size: 40px;
  --heading-h3-font-style: normal;
  --heading-h3-font-weight: 700;
  --heading-h3-letter-spacing: -.4px;
  --heading-h3-line-height: 56px;
  --heading-h4-font-family: "RobotoCondensed-Bold", Helvetica;
  --heading-h4-font-size: 32px;
  --heading-h4-font-style: normal;
  --heading-h4-font-weight: 700;
  --heading-h4-letter-spacing: -.32px;
  --heading-h4-line-height: 44px;
  --heading-h5-font-family: "RobotoCondensed-Bold", Helvetica;
  --heading-h5-font-size: 24px;
  --heading-h5-font-style: normal;
  --heading-h5-font-weight: 700;
  --heading-h5-letter-spacing: -.24px;
  --heading-h5-line-height: normal;
  --heading-h6-font-family: "RobotoCondensed-Bold", Helvetica;
  --heading-h6-font-size: 16px;
  --heading-h6-font-style: normal;
  --heading-h6-font-weight: 700;
  --heading-h6-letter-spacing: 0px;
  --heading-h6-line-height: 150%;
  --label-01-font-family: "RobotoCondensed-Regular", Helvetica;
  --label-01-font-size: 24px;
  --label-01-font-style: normal;
  --label-01-font-weight: 400;
  --label-01-letter-spacing: 0px;
  --label-01-line-height: 32px;
  --label-02-font-family: "RobotoCondensed-Regular", Helvetica;
  --label-02-font-size: 16px;
  --label-02-font-style: normal;
  --label-02-font-weight: 400;
  --label-02-letter-spacing: 0px;
  --label-02-line-height: normal;
  --label-03-font-family: "RobotoCondensed-Regular", Helvetica;
  --label-03-font-size: 18px;
  --label-03-font-style: normal;
  --label-03-font-weight: 400;
  --label-03-letter-spacing: 0px;
  --label-03-line-height: normal;
  --light-orange: #fff5eb;
  --paragraph-font-family: "RobotoCondensed-Regular", Helvetica;
  --paragraph-font-size: 16px;
  --paragraph-font-style: normal;
  --paragraph-font-weight: 400;
  --paragraph-letter-spacing: 0px;
  --paragraph-line-height: 24px;
  --primary: #ffd2a4;
  --white: #fff;
}

.hover\:bg-opacity-80:hover {
  --tw-bg-opacity: .8;
}

.hover\:text-c-main:hover {
  --tw-text-opacity: 1;
  color: rgb(196 143 90 / var(--tw-text-opacity));
}

.hover\:underline:hover {
  text-decoration-line: underline;
}

.focus\:border-blue-300:focus {
  --tw-border-opacity: 1;
  border-color: rgb(147 197 253 / var(--tw-border-opacity));
}

.focus\:outline-none:focus {
  outline-offset: 2px;
  outline: 2px solid #0000;
}

.focus\:ring:focus {
  --tw-ring-offset-shadow: var(--tw-ring-inset) 0 0 0 var(--tw-ring-offset-width) var(--tw-ring-offset-color);
  --tw-ring-shadow: var(--tw-ring-inset) 0 0 0 calc(3px + var(--tw-ring-offset-width)) var(--tw-ring-color);
  box-shadow: var(--tw-ring-offset-shadow), var(--tw-ring-shadow), var(--tw-shadow, 0 0 #0000);
}

@media (width >= 768px) {
  .md\:visible {
    visibility: visible;
  }

  .md\:block {
    display: block;
  }

  .md\:hidden {
    display: none;
  }

  .md\:h-auto {
    height: auto;
  }

  .md\:w-1\/2 {
    width: 50%;
  }

  .md\:w-\[160px\] {
    width: 160px;
  }

  .md\:w-\[50\%\] {
    width: 50%;
  }

  .md\:flex-row {
    flex-direction: row;
  }

  .md\:gap-x-10 {
    column-gap: 2.5rem;
  }

  .md\:p-7 {
    padding: 1.75rem;
  }

  .md\:text-start {
    text-align: start;
  }
}

@media (width >= 1024px) {
  .lg\:visible {
    visibility: visible;
  }

  .lg\:mb-0 {
    margin-bottom: 0;
  }

  .lg\:mr-8 {
    margin-right: 2rem;
  }

  .lg\:hidden {
    display: none;
  }

  .lg\:h-0 {
    height: 0;
  }

  .lg\:h-auto {
    height: auto;
  }

  .lg\:w-1\/2 {
    width: 50%;
  }

  .lg\:w-1\/4 {
    width: 25%;
  }

  .lg\:w-\[75\%\] {
    width: 75%;
  }

  .lg\:max-w-md {
    max-width: 28rem;
  }

  .lg\:flex-row {
    flex-direction: row;
  }

  .lg\:items-center {
    align-items: center;
  }

  .lg\:gap-x-4 {
    column-gap: 1rem;
  }

  .lg\:text-4xl {
    font-size: 2.25rem;
    line-height: 2.5rem;
  }
}
/*# sourceMappingURL=index.0b446bdd.css.map */
